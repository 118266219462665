import {takeEvery} from 'redux-saga/effects'
import fetchData from '../../SagaHelper'
import {
  ADD_RESOURCE_CATEGORY,
  DELETE_RESOURCE_CATEGORY,
  GET_RESOURCE_CATEGORIES,
  GET_RESOURCE_CATEGORY,
  UPDATE_RESOURCE_CATEGORY,
} from 'setup/redux/Actions/ResourceCategory/ActionTypes/ApiActionTypes'

function* dataSaga() {
  yield takeEvery(GET_RESOURCE_CATEGORIES, fetchData)
  yield takeEvery(GET_RESOURCE_CATEGORY, fetchData)
  yield takeEvery(ADD_RESOURCE_CATEGORY, fetchData)
  yield takeEvery(UPDATE_RESOURCE_CATEGORY, fetchData)
  yield takeEvery(DELETE_RESOURCE_CATEGORY, fetchData)
}

export default dataSaga
